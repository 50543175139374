import { lazy, Suspense, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Skeleton from "@mui/material/Skeleton"
import Badge from "@mui/material/Badge"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import CloseIcon from "@mui/icons-material/Close"
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined"

import { toggleBotInterface } from "../../../data/store/botSlice"
import { IMG_LOGO } from "../../../data/assets"

import ErrorBoundary from "../ErrorBoundary"
import TrainAndTest from "../TrainAndTest"

const Feedback = lazy(() => import("../Feedback"))
const EndSessionConfirmation = lazy(() => import("../EndSessionConfirmation"))

const Header = () => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.themeDetails.loading)
  const botName = useSelector((state) => state.themeDetails.botName)
  const headerHeight = useSelector((state) => state.themeDetails.headerHeight)
  const avatarUrl = useSelector((state) => state.themeDetails.avatarUrl)
  const fullScreenBot = useSelector((state) => state.themeDetails.fullScreenBot)
  const mobileView = useSelector((state) => state.themeDetails.mobileView)
  const connectionStatusText = useSelector(
    (state) => state.themeDetails.languageTemplate.connectionStatusText
  )
  const chatStatus = useSelector((state) => state.botDetails.chatStatus)
  const sessionId = useSelector((state) => state.botDetails.sessionId)
  const isSocketConnected = useSelector(
    (state) => state.botDetails.isSocketConnected
  )
  const showFeedback = useSelector((state) => state.themeDetails.showFeedback)
  const direction = useSelector((state) => state.themeDetails.direction)

  const [openDialog, setOpenDialog] = useState(false)
  const urlParams = new URLSearchParams(window.location.search)
  const isTraining = urlParams.get("training") || "false"

  const handleCloseChatbot = (e, reason) => {
    if (reason && reason === "backdropClick") return
    setOpenDialog(false)
    if (!fullScreenBot) dispatch(toggleBotInterface())
  }

  const handleOpenDialog = () => {
    if (sessionId) setOpenDialog(true)
  }

  return (
    <Box
      px={2}
      position="relative"
      display="flex"
      flexDirection="row"
      alignItems="center"
      backgroundColor="primary.main"
      color="primary.contrastText"
      minHeight={headerHeight}
      sx={
        chatStatus?.currentStatus === "assigned" ||
        chatStatus?.currentStatus === "unassigned"
          ? {
              backgroundImage: (theme) =>
                `-webkit-linear-gradient(55deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            }
          : {}
      }
    >
      {loading ? (
        <Skeleton
          animation="wave"
          variant="circular"
          width={`calc(${headerHeight}/2)`}
          height={`calc(${headerHeight}/2)`}
          sx={{ mr: 1 }}
        />
      ) : (
        <>
          {chatStatus?.currentStatus === "assigned" ||
          chatStatus?.currentStatus === "unassigned" ? (
            <Box
              className="Animated FadeIn"
              py={2.5}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Badge
                variant="dot"
                color={isSocketConnected ? "success" : "error"}
                overlap="circular"
                sx={{
                  "& .MuiBadge-badge": {
                    border: 1,
                    borderColor: "background.paper",
                  },
                }}
              >
                <Avatar
                  sx={{
                    width: 72,
                    height: 72,
                  }}
                  src={chatStatus.agentImgUrl || avatarUrl}
                  alt={chatStatus.agentName}
                />
              </Badge>
              <Typography
                variant="caption"
                fontWeight="fontWeightBold"
                mt={0.5}
                sx={(theme) => ({
                  opacity: theme.palette.action.disabledOpacity,
                })}
              >
                {isSocketConnected
                  ? connectionStatusText.online
                  : connectionStatusText.offline}
              </Typography>
              {chatStatus?.currentStatus === "assigned" && (
                <>
                  <Typography variant="h6">{chatStatus.agentName}</Typography>
                  <Typography variant="subtitle2">{botName}</Typography>
                </>
              )}
              {chatStatus?.currentStatus === "unassigned" && (
                <>
                  <Typography variant="h6">{botName}</Typography>
                  <Typography
                    variant="subtitle2"
                    sx={(theme) => ({
                      opacity: theme.palette.action.disabledOpacity,
                    })}
                  >
                    You are in the queue, please wait.
                  </Typography>
                </>
              )}
            </Box>
          ) : (
            <>
              <Badge
                variant="dot"
                color={isSocketConnected ? "success" : "error"}
                overlap="circular"
              >
                <Avatar
                  sx={{
                    width: `calc(${headerHeight}/2)`,
                    height: `calc(${headerHeight}/2)`,
                  }}
                  src={avatarUrl || IMG_LOGO}
                />
              </Badge>
              <Box
                mx={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography
                  variant="h6"
                  fontWeight="fontWeightBold"
                  sx={{ lineHeight: 1 }}
                >
                  {botName}
                </Typography>
                <Typography
                  variant="caption"
                  fontWeight="fontWeightBold"
                  sx={(theme) => ({
                    opacity: theme.palette.action.disabledOpacity,
                  })}
                >
                  {isSocketConnected
                    ? connectionStatusText.online
                    : connectionStatusText.offline}
                </Typography>
              </Box>
            </>
          )}
          {isTraining === "true" && (
            <Box flexGrow={1}
                 marginRight={ direction === "rtl" ? 0: 4}
                 marginLeft={ direction === "rtl" ? 4: 0}>
              <TrainAndTest />
            </Box>
          )}

          <Box
            position="absolute"
            top={10}
            {...(direction === "rtl" ? { left: 10 } : { right: 10 })}
          >
            <IconButton
              aria-label="minimizeChatbot"
              sx={{
                color: "inherit",
                display: fullScreenBot || !mobileView ? "none" : "",
              }}
              onClick={handleCloseChatbot}
            >
              <ZoomInMapOutlinedIcon fontSize="inherit" />
            </IconButton>
            {sessionId && (
              <IconButton
                className="Animated FadeIn"
                aria-label="endChat"
                sx={{
                  color: "inherit",
                }}
                onClick={handleOpenDialog}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          </Box>
          <Dialog
            aria-labelledby="customer-feedback"
            maxWidth="sm"
            slotProps={{
              backdrop: {
                invisible: true,
              },
            }}
            open={openDialog}
            onClose={handleCloseChatbot}
            fullWidth
            disableEscapeKeyDown
          >
            {showFeedback && (
              <IconButton
                sx={{
                  position: "absolute",
                  top: 4,
                  ...(direction === "rtl" ? { left: 4 } : { right: 4 }),
                }}
                onClick={() => setOpenDialog(false)}
              >
                <CloseIcon />
              </IconButton>
            )}
            <DialogContent sx={{ pb: 1 }}>
              <ErrorBoundary onErrorCallback={handleCloseChatbot}>
                <Suspense fallback={null}>
                  {showFeedback ? (
                    <Feedback onClose={handleCloseChatbot} />
                  ) : (
                    <EndSessionConfirmation
                      onCancel={() => setOpenDialog(false)}
                      onClose={handleCloseChatbot}
                    />
                  )}
                </Suspense>
              </ErrorBoundary>
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  )
}

export default Header
