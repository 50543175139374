import { useState } from "react"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import CloseIcon from "@mui/icons-material/Close"
import ImageIcon from "@mui/icons-material/Image"
import DescriptionIcon from "@mui/icons-material/Description"

import { getFormattedSize, openUrlInNewTab } from "../../../data/configs/utils"

const UploadedDocumentMessage = ({ payload }) => {
  const [showPreview, setShowPreview] = useState(false)

  const handleClosePreview = () => {
    setShowPreview(false)
  }

  const handleOpenPreview = () => {
    if (payload.fileUrl) setShowPreview(true)
  }

  const handleDownload = () => {
    if (payload.fileUrl)
      openUrlInNewTab(payload.fileUrl, "_blank")
  }

  return (
    <Box display="flex" flexDirection="row">
      {payload.fileType.indexOf("image/") !== -1 ? (
        <Avatar
          src={payload.fileUrl}
          alt="user-uploaded-img"
          variant="rounded"
          sx={{ height: 70, width: 70, mr: 2, cursor: "pointer" }}
          onClick={handleOpenPreview}
        >
          <ImageIcon fontSize="large" />
        </Avatar>
      ) : (
        <Avatar
          variant="rounded"
          sx={{ height: 70, width: 70, mr: 2, cursor: "pointer" }}
          onClick={handleDownload}
        >
          <DescriptionIcon fontSize="large" />
        </Avatar>
      )}
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography
          component="p"
          fontWeight="fontWeightMedium"
          sx={{ wordBreak: "break-all" }}
        >
          {payload.fileName}
        </Typography>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          {payload.pages && (
            <Typography component="span" variant="caption" mr={1}>
              {`${payload.pages} ${payload.pages > 1 ? "Pages" : "Page"}`}
            </Typography>
          )}
          {payload.fileType && (
            <Typography
              component="span"
              variant="caption"
              textTransform="uppercase"
              mr={1}
            >
              {payload.fileType}
            </Typography>
          )}
          {payload.fileSize && (
            <Typography component="span" variant="caption" mr={1}>
              {getFormattedSize(payload.fileSize)}
            </Typography>
          )}
        </Box>
      </Box>
      <Dialog
        aria-labelledby="od-uploaded-image-preview"
        open={showPreview}
        maxWidth="md"
        onClose={handleClosePreview}
        fullWidth
      >
        <IconButton
          size="small"
          sx={{
            zIndex: 1,
            position: "absolute",
            right: 4,
            top: 4,
            backgroundColor: "background.paper",
            "&:hover": {
              backgroundColor: "background.default",
            },
          }}
          onClick={handleClosePreview}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box
            component="img"
            src={payload.fileUrl}
            alt="od-uploaded-image"
            width="100%"
          />
        </DialogContent>
      </Dialog>
    </Box>
  )
}

UploadedDocumentMessage.propTypes = {
  payload: PropTypes.object.isRequired,
}

export default UploadedDocumentMessage
