import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"

const SpinnerLoader = ({ ...restProps }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex={1}
      p={1}
      position="absolute"
      left={0}
      right={0}
      top={0}
      bottom={0}
      {...restProps}
    >
      <CircularProgress color="secondary" size={40} />
    </Box>
  )
}

export default SpinnerLoader
