import { useSelector } from "react-redux"
import PropTypes from "prop-types"

import Body from "../../../components/Body"

const ChatBody = () => {
  const messages = useSelector((state) => state.botDetails.messages)

  return <Body messages={messages} />
}

ChatBody.propTypes = {
  messages: PropTypes.array,
}

ChatBody.defaultProps = {
  messages: [],
}

export default ChatBody
