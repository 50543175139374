import { DEFAULT_THEME_VARIABLES } from "../configs/constants"

const theme = ({
  primaryColor = DEFAULT_THEME_VARIABLES.PRIMARY_COLOR,
  secondaryColor = DEFAULT_THEME_VARIABLES.SECONDARY_COLOR,
  fontColor = DEFAULT_THEME_VARIABLES.FONT_COLOR,
  fontSize = DEFAULT_THEME_VARIABLES.FONT_SIZE,
  direction = DEFAULT_THEME_VARIABLES.DIRECTION,
}) => {
  const fontSizeValue = fontSize.trim().split("px")[0]

  const customTheme = {
    direction,
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      text: {
        primary: fontColor,
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
          },
        },
      },
    },
    typography: {
      fontSize: fontSizeValue - 2,
      caption: {
        fontSize: "0.65rem",
      },
    },
  }
  return customTheme
}

export default theme
