import React from "react"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

const StyledTypography = styled(Typography)(({ theme }) => ({
  height: theme.spacing(0.75),
  width: theme.spacing(0.75),
  marginRight: theme.spacing(0.25),
  marginLeft: theme.spacing(0.25),
  borderRadius: 10,
  display: "inline-block",
  backgroundColor: theme.palette.primary.main,
  position: "relative",
  bottom: 0,
}))

const JumpingDots = () => {
  return (
    <Typography component="span">
      <StyledTypography
        component="span"
        className="Jump"
        sx={{
          animationDelay: "200ms",
        }}
      />

      <StyledTypography
        component="span"
        className="Jump"
        sx={{
          animationDelay: "400ms",
        }}
      />

      <StyledTypography
        component="span"
        className="Jump"
        sx={{
          animationDelay: "600ms",
        }}
      />
    </Typography>
  )
}

export default JumpingDots
