import { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { grey } from "@mui/material/colors"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"
import CircularProgress from "@mui/material/CircularProgress"
import Tooltip from "@mui/material/Tooltip"
import WatchLaterIcon from "@mui/icons-material/WatchLater"
import FlagIcon from "@mui/icons-material/Flag"
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined"
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined"
import ThumbDownIcon from "@mui/icons-material/ThumbDown"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"

import {
  updateBotState,
  messageUpvoteDownvote,
} from "../../../data/store/botSlice"

import {
  IFRAME_EVENT_TYPE,
  MESSAGE_READ_STATUS,
  MESSAGE_SENDER,
  THEME_MODE,
} from "../../../data/configs/constants"
import {Alert} from "@mui/lab";
import Button from "@mui/material/Button";
import {copyToClipboard, sendUpdateToParent} from "../../../data/configs/utils";

const MessageWrapper = ({
  id,
  scrollableContainerNode,
  readStatus,
  trainingDisabled,
  chatLogId,
  isLast,
  isNewUserMsg,
  sender,
  timestamp,
  status,
  flag,
  senderInfo,
  markingFlag,
  customerVote,
  children,
  onRetry,
  notification,
  onClickFlag,
                          failureDetails,
  onMessageSeen,
  showEditAndTrain = false,
  editAndTrainPayload
}) => {
  const dispatch = useDispatch()
  const containerRef = useRef(null)
  const tenantId = useSelector((state) => state.botDetails.tenantId)
  const avatarUrl = useSelector((state) => state.themeDetails.avatarUrl)
  const bubbleShape = useSelector((state) => state.themeDetails.bubbleShape)
  const themeMode = useSelector((state) => state.themeDetails.themeMode)
  const isTestBot = useSelector((state) => state.botDetails.isTestBot)
  const direction = useSelector((state) => state.themeDetails.direction)
  const sourceChatLogId = useSelector(
    (state) => state.botDetails.knowledgeSource?.chatLogId
  )
  const [ratingLoading, setRatingLoading] = useState(false)

  useEffect(() => {
    let observer = null

    if (
      !notification &&
      (sender === MESSAGE_SENDER.AGENT || sender === MESSAGE_SENDER.CHATBOT) &&
      id &&
      onMessageSeen &&
      readStatus === MESSAGE_READ_STATUS.DELIVERED
    )
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting)
              onMessageSeen(id, MESSAGE_READ_STATUS.SEEN)
          })
        },
        {
          root: document.getElementById(scrollableContainerNode) || null,
          threshold: 0.5,
        }
      )

    const node = containerRef.current
    if (node && observer) observer.observe(node)

    return () => {
      if (node && observer) observer.unobserve(node)
    }
  }, [
    id,
    notification,
    onMessageSeen,
    readStatus,
    scrollableContainerNode,
    sender,
  ])

  const handleClickBrain = () => {
    dispatch(updateBotState({ showKnowledgeSource: true }))
  }

  const handleMessageRating = (value) => {
    const rating =
      value === "upvote"
        ? customerVote === "upvote"
          ? ""
          : "upvote"
        : customerVote === "downvote"
        ? ""
        : "downvote"
    setRatingLoading(true)
    dispatch(
      messageUpvoteDownvote({
        tenantId,
        chatLogId,
        messageId: id,
        customerVote: rating,
      })
    )
      .unwrap()
      .finally(() => {
        setRatingLoading(false)
      })
  }

  if (sender === MESSAGE_SENDER.CHATBOT || sender === MESSAGE_SENDER.AGENT)
    return (
      <Box
        id={id}
        ref={containerRef}
        position="relative"
        pl={notification ? 0 : 1}
        pr={notification ? 0 : 7}
        mb={isLast ? 1 : 0.5}
        mt={1}
        display="flex"
        flexDirection={notification ? "row-reverse" : "row"}
        className="HoverableWrapper Animated FadeIn"
      >
        {/*{!notification &&*/}
        {/*  isLast &&*/}
        {/*  (sender === MESSAGE_SENDER.AGENT ? (*/}
        {/*    <Avatar*/}
        {/*      className="Animated FadeIn"*/}
        {/*      sx={{*/}
        {/*        width: 24,*/}
        {/*        height: 24,*/}
        {/*        position: "absolute",*/}
        {/*        bottom: 4,*/}
        {/*        ...(direction === "rtl" ? { right: 18 } : { left: 18 }),*/}
        {/*      }}*/}
        {/*      src={senderInfo.imgUrl ? senderInfo.imgUrl : ""}*/}
        {/*      children={senderInfo?.pseudoName?.charAt(0) || false}*/}
        {/*    />*/}
        {/*  ) : (*/}
        {/*    <Avatar*/}
        {/*      className="Animated FadeIn"*/}
        {/*      sx={{*/}
        {/*        width: 24,*/}
        {/*        height: 24,*/}
        {/*        position: "absolute",*/}
        {/*        bottom: 4,*/}
        {/*        ...(direction === "rtl" ? { right: 18 } : { left: 18 }),*/}
        {/*      }}*/}
        {/*      src={avatarUrl}*/}
        {/*    />*/}
        {/*  ))}*/}
        <Box
          position="relative"
          p={2}
          pb={chatLogId ? 3 : 2}
          minWidth="100px"
          maxWidth={notification ? "100%" : "95%"}
          boxShadow={notification ? 2 : 0}
          sx={(theme) => ({
            backgroundColor:
              themeMode === THEME_MODE.DARK ? "background.default" : grey[100],
            ...(bubbleShape === "cornered"
              ? {
                  borderTopLeftRadius: theme.spacing(2),
                  borderTopRightRadius: theme.spacing(2),
                  borderBottomRightRadius: notification ? 0 : theme.spacing(2),
                  borderBottomLeftRadius: notification ? theme.spacing(2) : 0,
                }
              : bubbleShape === "rounded"
              ? { borderRadius: 4 }
              : { borderRadius: 1 }),
          })}
        >
          {!notification && isTestBot && (
            <Box
              position="absolute"
              display="flex"
              top={5}
              mx={0.5}
              {...(direction === "rtl" ? { right: "100%" } : { left: "100%" })}
            >
              {sourceChatLogId && chatLogId === sourceChatLogId && (
                <Tooltip title="See knowledge source">
                  <IconButton
                    className="Animated FadeIn"
                    aria-label="redirect"
                    size="small"
                    onClick={handleClickBrain}
                  >
                    <PsychologyOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
          {children}
          {showEditAndTrain && id && chatLogId && (
            <Button variant="contained" size='small' sx={{ my: 1, display:'block', float:'right' }} onClick={()=>{
              onClickFlag()
              sendUpdateToParent(IFRAME_EVENT_TYPE.TRAIN_AND_TEST_CLICKED, editAndTrainPayload)
            }}>
              Edit and Train
            </Button>
          )}
          {failureDetails ? <Alert variant="outlined" severity="warning" sx={{py:0.125, my:1}} action={
            <Button color="inherit" size="small" onClick={()=>copyToClipboard(`(${failureDetails?.code}) ${failureDetails?.internalMessage}`)}>
              COPY
            </Button>
          }>({failureDetails?.code}) {failureDetails?.internalMessage}</Alert>: null}
          {sender === MESSAGE_SENDER.CHATBOT && id && chatLogId && (
            <Box
              className="Animated FadeIn"
              position="absolute"
              left={0}
              px={1}
              bottom={0}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <IconButton
                aria-label="thumsup"
                size="small"
                disabled={ratingLoading}
                onClick={() => handleMessageRating("upvote")}
              >
                <ThumbUpIcon
                  fontSize="inherit"
                  color={customerVote === "upvote" ? "primary" : ""}
                />
              </IconButton>
              <IconButton
                aria-label="thumsdown"
                size="small"
                disabled={ratingLoading}
                onClick={() => handleMessageRating("downvote")}
              >
                <ThumbDownIcon
                  fontSize="inherit"
                  color={customerVote === "downvote" ? "primary" : ""}
                />
              </IconButton>
            </Box>
          )}
          {timestamp && (
            <Box
              className="Animated FadeIn WrapperHoverHidden"
              position="absolute"
              right={0}
              px={1}
              bottom={0}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Typography variant="caption" align="right" mr={0.5}>
                {new Date(timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    )
  else if (!notification && sender === MESSAGE_SENDER.USER)
    return (
      <Box
        ref={containerRef}
        px={2}
        mt={1}
        mb={isLast ? 2 : 1}
        display="flex"
        flexDirection="row-reverse"
        className="Animated FadeIn"
      >
        <Box
          className="HoverableWrapper"
          position="relative"
          p={2}
          backgroundColor="primary.main"
          color="primary.contrastText"
          minWidth="120px"
          maxWidth="95%"
          sx={(theme) => ({
            ...(bubbleShape === "cornered"
              ? {
                  borderTopLeftRadius: theme.spacing(2),
                  borderTopRightRadius: theme.spacing(2),
                  borderBottomLeftRadius: theme.spacing(2),
                }
              : bubbleShape === "rounded"
              ? { borderRadius: 4 }
              : { borderRadius: 1 }),
          })}
        >
          {children}
          <Box
            className={`Animated FadeIn ${
              isNewUserMsg || status === "failed" || status === "pending"
                ? ""
                : "WrapperHoverHidden"
            }`}
            position="absolute"
            right={0}
            px={1}
            bottom={0}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
          >
            {status === "failed" && (
              <Typography
                variant="caption"
                mx={0.5}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={onRetry}
              >
                Retry
              </Typography>
            )}
            <Typography variant="caption" align="right" mr={0.5}>
              {status === "pending" && (
                <WatchLaterIcon
                  fontSize="inherit"
                  sx={{
                    verticalAlign: "middle",
                    mx: 0.5,
                  }}
                />
              )}
              {new Date(timestamp).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  return null
}

MessageWrapper.propTypes = {
  id: PropTypes.string,
  scrollableContainerNode: PropTypes.string,
  trainingDisabled: PropTypes.bool,
  isLast: PropTypes.bool,
  isNewUserMsg: PropTypes.bool,
  sender: PropTypes.string,
  timestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onRetry: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  notification: PropTypes.bool,
  flag: PropTypes.bool,
  markingFlag: PropTypes.bool,
  senderInfo: PropTypes.object,
  readStatus: PropTypes.string,
  onMessageSeen: PropTypes.func,
}

MessageWrapper.defaultProps = {
  status: null,
  isLast: false,
  isNewUserMsg: false,
  notification: false,
  flag: false,
  markingFlag: false,
  trainingDisabled: false,
  senderInfo: {},
  readStatus: "",
  scrollableContainerNode: "chatbotBody",
}

export default MessageWrapper
