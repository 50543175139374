import { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import CancelIcon from "@mui/icons-material/Cancel"

import {
  DEFAULT_THEME_VARIABLES,
  IFRAME_EVENT_TYPE,
} from "../../../data/configs/constants"
import { sendUpdateToParent } from "../../../data/configs/utils"
import {
  toggleBotInterface,
  addMessages,
  updateRouteBasedNotifications,
  updateBotState,
} from "../../../data/store/botSlice"

import Body from "../../components/Body"

const Notifications = ({ parentPathName }) => {
  const dispatch = useDispatch()
  const notificationRef = useRef(null)
  const scrollTimer = useRef(false)
  const routeNotificationTimer = useRef(null)

  const unReadMessages = useSelector((state) => state.botDetails.unReadMessages)
  const routeBasedNotifications = useSelector(
    (state) => state.botDetails.routeBasedNotifications
  )

  useEffect(() => {
    return () => {
      if (scrollTimer.current) clearTimeout(scrollTimer.current)
    }
  }, [])

  useEffect(() => {
    if (notificationRef?.current)
      sendUpdateToParent(IFRAME_EVENT_TYPE.MINIMIZE_CHATBOT, {
        height: `calc(${DEFAULT_THEME_VARIABLES.TRIGGER_SIZE} + ${DEFAULT_THEME_VARIABLES.TRIGGER_BOT_OFFSET} + ${notificationRef?.current?.scrollHeight}px + 32px)`,
        width: `calc(${DEFAULT_THEME_VARIABLES.NOTIFICATION_BODY_WIDTH} + 24px)`,
      })

    if (scrollTimer.current) clearTimeout(scrollTimer.current)
    if (notificationRef.current)
      scrollTimer.current = setTimeout(() => {
        notificationRef.current.scroll({
          top: notificationRef.current.scrollHeight,
          behavior: "smooth",
        })
      }, 750)
  }, [unReadMessages.length])

  useEffect(() => {
    let msgIntervalTimer = null
    if (
      parentPathName &&
      routeBasedNotifications[parentPathName] &&
      routeBasedNotifications[parentPathName].messages.length > 0 &&
      !routeBasedNotifications[parentPathName].freeze
    ) {
      routeNotificationTimer.current = setTimeout(() => {
        let index = 0
        msgIntervalTimer = setInterval(() => {
          dispatch(
            addMessages({
              messages: [
                {
                  ...routeBasedNotifications[parentPathName].messages[index],
                  timestamp: new Date().getTime(),
                },
              ],
            })
          )
          index = index + 1
          if (
            index === routeBasedNotifications[parentPathName].messages.length
          ) {
            dispatch(
              updateRouteBasedNotifications({
                pathname: parentPathName,
                changedValues: {
                  freeze: true,
                },
              })
            )
            clearInterval(msgIntervalTimer)
          }
        }, 1000)
      }, routeBasedNotifications[parentPathName].waitingTime * 1000)
    }

    return () => {
      if (msgIntervalTimer) clearInterval(msgIntervalTimer)
      if (routeNotificationTimer.current)
        clearTimeout(routeNotificationTimer.current)
    }
  }, [dispatch, parentPathName, routeBasedNotifications])

  const handleNotificationClick = () => {
    dispatch(toggleBotInterface())
  }

  const handleNotificationBotClose = () => {
    dispatch(updateBotState({ unReadMessages: [] }))
  }

  if (unReadMessages.length === 0) return null

  return (
    <Box
      position="fixed"
      bottom={`calc(${DEFAULT_THEME_VARIABLES.TRIGGER_SIZE} + ${DEFAULT_THEME_VARIABLES.TRIGGER_BOT_NOTIFICATION_OFFSET})`}
      maxWidth={DEFAULT_THEME_VARIABLES.NOTIFICATION_BODY_WIDTH}
      right="8px"
    >
      <Box display="flex" justifyContent="flex-end" mb={0.5}>
        <IconButton
          aria-label="NotificationBotClose"
          sx={{ borderRadius: 1 }}
          onClick={handleNotificationBotClose}
        >
          <CancelIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box
        ref={notificationRef}
        maxHeight={`calc(88vh - ${DEFAULT_THEME_VARIABLES.TRIGGER_SIZE} + ${DEFAULT_THEME_VARIABLES.TRIGGER_BOT_NOTIFICATION_OFFSET})`}
        overflow="auto"
        onClick={handleNotificationClick}
        sx={{ cursor: "pointer" }}
      >
        <Body messages={unReadMessages} notification />
      </Box>
    </Box>
  )
}

Notifications.propTypes = {
  parentPathName: PropTypes.string,
}

Notifications.defaultProps = {
  parentPathName: "",
}

export default Notifications
