import {getPsid, getUrlParams} from "../../configs/utils"

const params = getUrlParams()

const initialState = {
  socketRequestProcessing: false,
  isSocketConnected: false,
  psid: getPsid(),
  tenantId: params.tenantId,
  isTestBot: params.testBot === "true",
  sessionId: null,
  startNewSession: true,
  messages: [],
  welcomeMessages: [],
  unReadMessages: [],
  quickReplies: [],
  autoSuggestQuickReplies: [],
  isBotOpen: false,
  modalLoading: false,
  snackbarProps: {open: false},
  routeBasedNotifications: {},
  enableLiveChatButton: false,
  typingInfo: {},
  chatStatus: null,
  firstUnreadMessageId: "",
  knowledgeSource: {},
  showKnowledgeSource: false,
  relay: {
    payload:{},
    data: []
  },
  userLoginData: {}
}

export default initialState
