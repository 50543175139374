import PropTypes from "prop-types"
import { useState, useRef, useEffect, lazy, Suspense } from "react"
import { useDispatch, useSelector } from "react-redux"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import SendIcon from "@mui/icons-material/Send"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt"
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import ImageIcon from "@mui/icons-material/Image"
import DescriptionIcon from "@mui/icons-material/Description"

import {
  addMessages,
  sendMessage,
  emitCustomEvent,
  updateBotState,
  sendMessageStream,
} from "../../../data/store/botSlice"

import {
  MESSAGE_SENDER,
  MESSAGE_TYPES,
  SOCKET_EVENTS,
} from "../../../data/configs/constants"
import { uniqueId, fileToBase64 } from "../../../data/configs/utils"

import QuickReply from "../QuickReply"
import ErrorBoundary from "../ErrorBoundary"
import SpinnerLoader from "../SpinnerLoader"
import ShowRelayData from "../ShowRelayData"
import * as _ from "lodash"
import { Divider } from "@mui/material"

const EmojiPicker = lazy(() => import("../EmojiPicker"))

const Footer = () => {
  const dispatch = useDispatch()
  const [inputText, setInputText] = useState("")
  const [typing, setTyping] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const loading = useSelector((state) => state.themeDetails.loading)
  const psid = useSelector((state) => state.botDetails.psid)
  const isTestBot = useSelector((state) => state.botDetails.isTestBot)
  const queryPlaceholder = useSelector(
    (state) => state.themeDetails.languageTemplate?.queryPlaceholder
  )
  const welcomeMessages = useSelector(
    (state) => state.botDetails.welcomeMessages
  )
  const messagesLength = useSelector(
    (state) => state.botDetails.messages?.length
  )
  const inputLock = useSelector(
    (state) => state.botDetails?.messages[messagesLength - 1]?.inputLock
  )
  const inputLockText = useSelector(
    (state) => state.botDetails?.messages[messagesLength - 1]?.inputLockText
  )
  const isQuickRepliesEmpty = useSelector(
    (state) => state.botDetails.quickReplies?.length === 0
  )
  const startNewSession = useSelector(
    (state) => state.botDetails.startNewSession
  )
  const chatStatus = useSelector((state) => state.botDetails.chatStatus)
  const mobileView = useSelector((state) => state.themeDetails.mobileView)
  const watermark = useSelector((state) => state.themeDetails.watermark)
  const branding = useSelector((state) => state.themeDetails.branding)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const didMount = useRef(false)
  const typingTimerRef = useRef(null)
  const fileUploaderInputRef = useRef(null)

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
    } else {
      dispatch(
        emitCustomEvent({
          event: SOCKET_EVENTS.TYPING_STATUS,
          data: {
            isTyping: typing,
            clientPsid: psid,
          },
        })
      )
    }
  }, [dispatch, psid, typing])

  useEffect(() => {
    let timer = null
    if (chatStatus?.currentStatus === "assigned")
      timer = setTimeout(() => {
        dispatch(
          emitCustomEvent({
            event: SOCKET_EVENTS.UNSENT_MESSAGE,
            data: {
              text: inputText,
              clientPsid: psid,
            },
          })
        )
      }, 700)

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [chatStatus?.currentStatus, dispatch, inputText, psid])

  const handleInputChange = (e) => {
    setInputText(e.target.value)
    setShowEmojiPicker(false)
  }

  const handleInputKeyDown = (e) => {
    clearTimeout(typingTimerRef.current)
    if (e.keyCode === 13 && !e.shiftKey && inputText?.trim().length > 0) {
      handleMessageSend(e)
      setTyping(false)
    } else {
      if (!typing) setTyping(true)
      typingTimerRef.current = setTimeout(() => {
        setTyping(false)
      }, 2000)
    }
  }

  const handleMessageSend = (e, reply) => {
    setShowEmojiPicker(false)
    let text = ""
    if (reply) {
      text = reply.trim()
    } else {
      e.preventDefault()
      text = inputText.trim()
    }

    if (text) {
      onSend({ text }, MESSAGE_TYPES.TEXT)
    }
  }

  const onSend = (data, messageType) => {
    const message = {
      id: uniqueId(),
      type: messageType,
      sender: MESSAGE_SENDER.USER,
      payload: {
        ...data,
      },
      status: "pending",
      timestamp: new Date().getTime(),
    }
    dispatch(addMessages({ messages: [message] }))
    setInputText("")
    const payload = {
      type: "message",
      info: {
        // platformName: PLATFORM,
        sender: MESSAGE_SENDER.USER,
        // tenantId,
        // psid,
      },
      metadata: {
        startNewSession,
        testBot: isTestBot,
      },
      message,
    }
    if (welcomeMessages.length === messagesLength)
      payload.metadata.welcomeMessages = welcomeMessages
    if(process.env.REACT_APP_STREAM_RESPONSE === "true") dispatch(sendMessageStream(payload))
    else dispatch(sendMessage(payload))
  }

  const handleQuickReply = (reply) => handleMessageSend(null, reply)

  const handleOpenEmojiPicker = () =>
    setShowEmojiPicker((prevState) => !prevState)

  const handleEmojiSelect = (emoji) => {
    setInputText((prevInputText) => prevInputText + emoji.native)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const clearExistingInput = (event) => {
    event.target.value = null
  }

  const handleFileUploadClick = (type) => {
    if (fileUploaderInputRef.current) {
      if (type === "image") {
        fileUploaderInputRef.current.accept = "image/*"
      } else if (type === "pdf") {
        fileUploaderInputRef.current.accept = "application/pdf"
      }
      fileUploaderInputRef.current.click()
    }
    handleMenuClose()
  }

  const handleFileUpload = (e) => {
    if (
      fileUploaderInputRef.current.accept === "image/*" &&
      e.target?.files[0]?.type?.includes("image")
    ) {
      if (e.target.files[0]?.size < 1024 * 1024 * 2) {
        const file = e.target.files[0]
        fileToBase64(file).then((fileUrl) => {
          const payload = {
            fileName: file.name,
            fileSize: file.size,
            fileType: file.type,
            fileUrl,
          }
          onSend(payload, MESSAGE_TYPES.UPLOADED_DOCUMENT)
        })
      } else
        dispatch(
          updateBotState({
            snackbarProps: {
              open: true,
              message: "Image must be smaller than 2MB",
            },
          })
        )
    } else if (
      fileUploaderInputRef.current.accept === "application/pdf" &&
      e.target?.files[0]?.type?.includes("application/pdf")
    ) {
      if (e.target.files[0]?.size < 1024 * 1024 * 2) {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsBinaryString(file)
        reader.onloadend = () => {
          const pages =
            reader.result?.match(/\/Type[\s]*\/Page[^s]/g)?.length || 0
          fileToBase64(file).then((fileUrl) => {
            const payload = {
              fileName: file.name,
              fileSize: file.size,
              fileType: file.type,
              fileUrl,
              pages,
            }
            onSend(payload, MESSAGE_TYPES.UPLOADED_DOCUMENT)
          })
        }
      } else
        dispatch(
          updateBotState({
            snackbarProps: {
              open: true,
              message: "Pdf must be smaller than 2MB",
            },
          })
        )
    } else
      dispatch(
        updateBotState({
          snackbarProps: {
            open: true,
            message: "Selected file is not valid",
          },
        })
      )
  }

  return (
    <>
      <input
        type="file"
        ref={fileUploaderInputRef}
        accept="*"
        onClick={clearExistingInput}
        onChange={handleFileUpload}
        hidden
      />
      {!isQuickRepliesEmpty && (
        <QuickReply inputLock={inputLock} onClick={handleQuickReply} />
      )}
      <Divider/>
      <ShowRelayData/>
      <Box
        p={1}
        position="relative"
        display="flex"
        flexDirection="row"
        sx={{
          mx: 0.5,
          // borderTop: 1,
          // borderColor: "grey.300",
        }}
      >

        {loading ? (
          <Skeleton animation="wave" height={25} width="75%" sx={{ m: 1 }} />
        ) : (
          <>
            {showEmojiPicker && (
              <ErrorBoundary>
                <Suspense fallback={<SpinnerLoader bottom="100%" mb={4} />}>
                  <Box
                    position="absolute"
                    left={0}
                    right={0}
                    bottom="100%"
                    p={1}
                    zIndex={1}
                  >
                    <EmojiPicker
                      theme="light"
                      onEmojiSelect={handleEmojiSelect}
                    />
                  </Box>
                </Suspense>
              </ErrorBoundary>
            )}
            {!mobileView &&
              (chatStatus?.currentStatus === "assigned" ||
                chatStatus?.currentStatus === "unassigned") && (
                <IconButton
                  size="small"
                  aria-label="emoji-trigger-icon"
                  color={showEmojiPicker ? "warning" : ""}
                  sx={{
                    alignSelf: "center",
                    borderRadius: 1,
                    mr: 0.5,
                  }}
                  onClick={handleOpenEmojiPicker}
                >
                  <EmojiEmotionsIcon />
                </IconButton>
              )}
            <TextField
              id="inputComposer"
              size="small"
              placeholder={inputLockText ? inputLockText : queryPlaceholder}
              sx={{
                flex: 1,
                "& fieldset": {
                  border: "none",
                },
              }}
              value={inputText}
              disabled={inputLock || false}
              minRows={1}
              maxRows={didMount.current ? 3 : 1}
              onChange={handleInputChange}
              InputProps={{
                onKeyDown: handleInputKeyDown,
              }}
              multiline
              autoFocus
            />
            {inputText?.trim() ? (
              <IconButton
                size="small"
                color="primary"
                aria-label="send-icon"
                sx={{
                  alignSelf: "end",
                  borderRadius: 1,
                  mb: 1,
                }}
                onClick={handleMessageSend}
              >
                <SendIcon />
              </IconButton>
            ) : (
              (chatStatus?.currentStatus === "assigned" ||
                chatStatus?.currentStatus === "unassigned") && (
                <IconButton
                  size="small"
                  aria-label="file-upload-icon"
                  aria-controls={open ? "file-upload-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{
                    alignSelf: "end",
                    borderRadius: 1,
                    mb: 1,
                    transform: open ? "rotate(45deg)" : "",
                    transition: "transform 500ms",
                  }}
                  onClick={handleMenuOpen}
                >
                  <AddCircleIcon />
                </IconButton>
              )
            )}
          </>
        )}
        <Menu
          id="live-chat-file-upload-menu"
          anchorEl={anchorEl}
          open={open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleFileUploadClick("image")}>
            <ImageIcon sx={{ mx: 1, color: "text.disabled" }} /> Image
          </MenuItem>
          <MenuItem onClick={() => handleFileUploadClick("pdf")}>
            <DescriptionIcon sx={{ mx: 1, color: "text.disabled" }} /> Document
          </MenuItem>
        </Menu>
      </Box>
      {watermark &&
        (branding?.active || branding?.brandName) && (
          <Typography
            variant="caption"
            align="center"
            display="block"
            sx={{
              color: "text.disabled",
              backgroundColor: "grey.100",
              p: 0.5
            }}
          >
            <ElectricBoltIcon fontSize="inherit" color="warning" /> by{" "}
            <Box
              component={branding.url ? 'a' : 'span'}
              href={branding.url}
              underline="none"
              target="_blank"
              fontWeight="fontWeightBold"
              color="text.disabled"
            >
              {branding.brandName}
            </Box>
            {branding.showVersion &&
              process.env.REACT_APP_VERSION &&
              `  ${process.env.REACT_APP_VERSION}`}
          </Typography>
        )
      }
    </>
  )
}

Footer.propTypes = {
  watermark: PropTypes.bool,
}

Footer.defaultProps = {
  watermark: true,
}

export default Footer
