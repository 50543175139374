import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { GET_THEME_CONFIG } from "../../configs/apis"
import { fetchDataAndProceed } from "../../configs/utils"

import initialState from "./initialState"
import { POWERED_BY_URL } from "../../configs/urls"

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    updateThemeState(state, action) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThemeConfig.pending, (state) => {
        state.loading = true
        state.loadError = false
      })
      .addCase(getThemeConfig.rejected, (state) => {
        state.loading = false
        state.loadError = true
      })
      .addCase(getThemeConfig.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loadError = false
        if (payload.data) {
          state.userLoginAttributes = payload.data.loginAttributes
          state.botName = payload.data.botName || initialState.botName
          state.primaryColor =
            payload.data.primaryColor || initialState.primaryColor
          state.secondaryColor =
            payload.data.secondaryColor || initialState.secondaryColor
          state.avatarUrl = payload.data.avatarUrl || initialState.avatarUrl
          state.triggerUrl = payload.data.triggerUrl || initialState.triggerUrl
          state.botPosition = payload.data.botPosition || initialState.botPosition
          state.triggerSize =
            payload.data.triggerSize || initialState.triggerSize
          state.triggerShape =
            payload.data.triggerShape || initialState.triggerShape
          state.bubbleShape =
            payload.data.bubbleShape || initialState.bubbleShape
          state.fontSize = payload.data.fontSize || initialState.fontSize
          state.headerHeight =
            payload.data.headerHeight || initialState.headerHeight
          state.liveChatEnabled =
            payload.data.liveChatEnabled || initialState.liveChatEnabled
          state.language = payload.data.language || initialState.language
          state.languageTemplate =
            payload.data.languageTemplate || initialState.languageTemplate
          state.branding = payload.data.branding || {
            active: true,
            brandName: "ORIMON",
            url: POWERED_BY_URL,
            showVersion: true,
          }
          state.showFeedback =
            payload.data.showFeedback || initialState.showFeedback
          state.direction = payload.data.direction || initialState.direction
        }
      })
  },
})

export const getThemeConfig = createAsyncThunk(
  "theme/getThemeConfig",
  async (payload) => {
    const response = await fetchDataAndProceed({
      url: GET_THEME_CONFIG.replace(":tenantId", payload.tenantId),
      data: payload.data,
    })
    return response.data
  }
)

export const { updateThemeState } = themeSlice.actions
export default themeSlice.reducer
