import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, IconButton, Stack, Tooltip, Typography, alpha } from '@mui/material';
import { clearRelayData } from '../../../data/store/botSlice';
import { useTheme } from '@emotion/react';


const ShowRelayData = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const relayData = useSelector((state) => _.get(state, 'botDetails.relay.data', []));
    // const [openedItem, setOpenedItem] = React.useState({ index: null, data: null });

    // const handleItemClicked = (index, data) => {
    //     if (openedItem.index === index) {
    //         setOpenedItem({ index: null, data: null });
    //         return;
    //     }
    //     setOpenedItem({ index, data });
    // };

    // const handleRemoveRelayData = (index) => {
    //     dispatch(removeRelayData({ deleteIndex: index }));
    //     setOpenedItem({ index: null, data: null });
    // };

    const handleClearRelayData = () => {
        dispatch(clearRelayData());
    }

    if (relayData.length === 0) return null;

    return (
        <Box padding={1} margin={1} sx={{
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            borderRadius: 2
        }}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <ImageStack images={relayData.map(data => data.image)} />
                <Box flexGrow={1}>
                    <Typography>
                        Know more about these products
                    </Typography>
                </Box>
                <Stack direction={'column'}>
                    <IconButton onClick={handleClearRelayData}>
                        <i class="ri-close-large-line"></i>
                    </IconButton>
                    {relayData.length > 1 && (
                        <Tooltip title="Compare" arrow>
                            <IconButton>
                                <i class="ri-pantone-line"></i>
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
};

const ImageStack = ({ images }) => {
    return (
        <Stack direction={'row-reverse'} spacing={1} position={'relative'} alignItems={'center'} height={64} width={64 + images.length * 5}>
            {images.map((image, index) => {
                console.log("logging height", images.length * 4, 64 - (2 * (images.length - index)));
                return (
                    <Box sx={{
                        position: 'absolute',
                        left: (images.length - index) * 5,
                        right: 0,
                        zIndex: images.length - index,
                    }}>
                        <Box sx={{ position: 'relative', width: 64, height: 64 }}>
                            <Card variant='outlined' component={'img'} src={image} width={64} height={64 - 2 * index} key={index} sx={{
                                objectFit: 'scale-down',
                            }} />
                            {index === 0 && images.length > 1 && (
                                <Box sx={{
                                    position: 'absolute',
                                    right: 2,
                                    bottom: 2,
                                    backgroundColor: 'primary.main',
                                    color: 'primary.contrastText',
                                    borderRadius: '50%',
                                    fontSize: 10,
                                    width: 24,
                                    height: 24,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {images.length}
                                </Box>
                            )}
                        </Box>
                    </Box>
                )
            })}
        </Stack>
    )
}

// const RelayDataDetails = ({ item, onRemove }) => (
//     <Box component={Paper}
//         variant='outlined'
//         position={'relative'}
//         className='FadeIn'
//         padding={0.5}
//         sx={(theme) => ({
//             backgroundColor: lighten(theme.palette.primary.main, 0.8)
//         })}>
//         <Grid container justifyContent={'space-between'} alignItems={'center'}>
//             <Grid item xs={5} height={50}>
//                 <Box
//                     component={'img'}
//                     src={item?.data?.image}
//                     alt=''
//                     borderRadius={1}
//                     width={'auto'}
//                     height={'100%'}
//                     style={{
//                         width: 'auto',
//                         objectFit: 'cover',
//                         objectPosition: 'center'
//                     }} />
//             </Grid>
//             <Grid item xs={7}>
//                 <Tooltip title={item?.data?.text}>
//                     <Typography noWrap>{item?.data?.text}</Typography>
//                 </Tooltip>
//             </Grid>
//         </Grid>
//         <IconButton size='small' onClick={() => onRemove(item?.index)} sx={(theme) => ({
//             position: 'absolute',
//             right: -5,
//             top: -15,
//             backgroundColor: 'primary.main',
//             '&:hover': {
//                 backgroundColor: darken(theme.palette.primary.main, 0.2)
//             }
//         })}>
//             <Delete color='secondary' fontSize='inherit' />
//         </IconButton>
//     </Box>
// );

export default ShowRelayData;
