import { FlagOutlined, RefreshOutlined } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { sendUpdateToParent } from '../../../data/configs/utils'
import { IFRAME_EVENT_TYPE } from '../../../data/configs/constants'

const TrainAndTest = () => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            backgroundColor="primary.main"
            color="primary.contrastText"
            justifyContent={"flex-end"}
        >
            <Button startIcon={<FlagOutlined style={{marginLeft: 8}} />} sx={{
                color:'common.white'
            }} onClick={() => {
                sendUpdateToParent(IFRAME_EVENT_TYPE.OPEN_TENANT_TRAINING_HISTORY, {})
            }}>History</Button>
            <Button startIcon={<RefreshOutlined style={{marginLeft: 8}} />} sx={{
                color:'common.white'
            }} onClick={() => {
                window.location.reload()
            }}>
                Refresh
            </Button>
        </Box>
    )
}

export default TrainAndTest