import { useState, useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

const QuickReply = ({ inputLock, onClick }) => {
  const quickReplies = useSelector((state) => state.botDetails.quickReplies)
  const autoSuggestQuickReplies = useSelector((state)=> state.botDetails.autoSuggestQuickReplies)
  const direction = useSelector((state) => state.themeDetails.direction)
  const trackRef = useRef(null)
  const trackContainerRef = useRef(null)
  const [data, setData] = useState({
    translate: 0,
    disableLeft: false,
    disableRight: true,
  })

  useEffect(() => {
    const viewWidth = trackContainerRef.current.offsetWidth
    const trackWidth = trackRef.current.scrollWidth
    setData({
      translate: 0,
      disableLeft: direction === "rtl" ? true : viewWidth > trackWidth,
      disableRight: direction === "rtl" ? viewWidth > trackWidth : true,
    })
  }, [quickReplies.length, direction])

  const handleTranslate = (dir) => {
    const viewWidth = trackContainerRef.current.offsetWidth
    const trackWidth = trackRef.current.scrollWidth
    if (viewWidth > trackWidth) {
      setData((prev) => ({
        ...prev,
        disableLeft: true,
        disableRight: true,
      }))
    } else {
      if (dir === "left") {
        let offset =
          direction === "rtl"
            ? data.translate
            : trackWidth - viewWidth + data.translate
        if (offset > 150) {
          setData((prev) => ({
            ...prev,
            translate: data.translate - 150,
            disableRight: false,
          }))
        } else {
          setData((prev) => ({
            ...prev,
            translate: data.translate - offset,
            disableLeft: true,
          }))
        }
      }
      if (dir === "right") {
        let offset =
          direction === "rtl"
            ? trackWidth - viewWidth - data.translate
            : -data.translate
        if (offset > 150) {
          setData((prev) => ({
            ...prev,
            translate: data.translate + 150,
            disableLeft: false,
          }))
        } else {
          setData((prev) => ({
            ...prev,
            translate: data.translate + offset,
            disableRight: true,
          }))
        }
      }
    }
  }

  if (quickReplies?.length > 0 || autoSuggestQuickReplies?.length > 0)
    return (
      <Box position="relative" width="100%" p={1}>
        <Box
          ref={trackContainerRef}
          width="100%"
          sx={{
            overflowX: "scroll",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            ref={trackRef}
            sx={{
              display: "inline-flex",
              flexWrap: "nowrap",
              transform: `translate3d( ${data.translate}px,0,0)`,
              transition: "transform 0.5s ease",
            }}
          >
              {autoSuggestQuickReplies?.map((reply, index) => {
              return (
                <Button
                  key={index}
                  variant="outlined"
                  size="small"
                  disabled={inputLock}
                  color="secondary"
                  sx={{
                    mr: 1,
                    // minWidth: 120,
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => onClick(reply)}
                >
                  {reply}
                </Button>
              )
            })}
            {quickReplies?.map((reply, index) => {
              return (
                <Button
                  key={index}
                  variant="outlined"
                  size="small"
                  disabled={inputLock}
                  sx={{
                    mr: 1,
                    // minWidth: 120,
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => onClick(reply)}
                >
                  {reply}
                </Button>
              )
            })}
          </Box>
        </Box>
      </Box>
    )
}

QuickReply.propTypes = {
  onClick: PropTypes.func,
  inputLock: PropTypes.bool,
}

QuickReply.defaultProps = {
  inputLock: false,
}

export default QuickReply
