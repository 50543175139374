import {lazy, useCallback, useEffect, useRef, Suspense} from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import Button from "@mui/material/Button"

import {
  markOrUnmarkFlagOnMessage,
  sendMessage,
  addMessages,
  updateMessage,
} from "../../../data/store/botSlice"
import { updateThemeState } from "../../../data/store/themeSlice"
import { BUBBLE_SHAPE, MESSAGE_SENDER, MESSAGE_TYPES } from "../../../data/configs/constants"
import {
  // linkify,
  // createPayloadForApi,
  playNotification,
  sendUpdateToParent,
  uniqueId,
  getUrlParams, copyToClipboard,
} from "../../../data/configs/utils"

import ErrorBoundary from "../ErrorBoundary"
import MessageWrapper from "../MessageWrapper"
import GlobalSnackBar from "../GlobalSnackBar"
import JumpingDots from "../JumpingDots"
import UploadedDocumentMessage from "../UploadedDocumentMessage"
import MessageCorouselData from "./MessageCorouselData.js"
import Markdown from "react-markdown"
import * as _ from "lodash"
import Alert from '@mui/material/Alert';
import { Stack, alpha } from "@mui/material"

const DynamicForm  = lazy(()=>import("../DynamicForm"))
const PARAMS = getUrlParams()

const Body = ({ messages, notification }) => {
  const dispatch = useDispatch()
  const psid = useSelector((state) => state.botDetails.psid)
  const tenantId = useSelector((state) => state.botDetails.tenantId)
  const typingInfo = useSelector((state) => state.botDetails.typingInfo)
  const loading = useSelector((state) => state.themeDetails.loading)
  const isTestBot = useSelector((state) => state.botDetails.isTestBot)
  const bubbleShape = useSelector((state) => state.themeDetails.bubbleShape)
  const startNewSession = useSelector(
    (state) => state.botDetails.startNewSession
  )
  const firstUnreadMessageId = useSelector(
    (state) => state.botDetails.firstUnreadMessageId
  )
  const bodyRef = useRef()
  const didMount = useRef(false)
  const scrollTimer = useRef(null)
  const urlParams = new URLSearchParams(window.location.search)
  const isTraining = urlParams.get("training") === "true"

  useEffect(() => {
    bodyRef.current.scrollTop = bodyRef.current.scrollHeight
    if (firstUnreadMessageId) {
      var firstUnreadMessage = document.getElementById(firstUnreadMessageId)
      firstUnreadMessage?.scrollIntoView()
    }
  }, [firstUnreadMessageId])

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
    } else {
      bodyRef.current.scroll({
        top: bodyRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [messages.length])

  useEffect(() => {
    if (document.hidden || notification) playNotification()
  }, [messages.length, notification])


  const handleRetrySend = (message) => {
    dispatch(
      sendMessage({
        type: "message",
        info: {
          sender: MESSAGE_SENDER.USER,
        },
        metadata: {},
        message,
      })
    )
  }

  const handleFlagClick = (message) => {
    const payload = {
      tenantId,
      psid,
      messageId: message.id,
      chatLogId: message.chatLogId,
      isTrainingRequired: !message.flag,
    }
    dispatch(markOrUnmarkFlagOnMessage(payload))
      .unwrap()
      .then((res) => {
        if (res?.data && res?.data?.isTrainingRequired)
          sendUpdateToParent("flag", res.data, PARAMS.parentOrigin)
      })
  }

  const handleButtonClick = (button) => {
    if (button?.props?.href) return
    if (button.text) {
      const message = {
        id: uniqueId(),
        type: MESSAGE_TYPES.TEXT,
        sender: MESSAGE_SENDER.USER,
        payload: {
          text: button.text,
        },
        status: "pending",
        timestamp: new Date().getTime(),
      }
      dispatch(addMessages({ messages: [message] }))
      dispatch(
        sendMessage({
          type: "message",
          info: {
            sender: MESSAGE_SENDER.USER,
          },
          metadata: {
            button,
          },
          message,
        })
      )
    }
  }

  const handleFormSubmit = (message) => {
    dispatch(
      updateMessage({ id: message.id, updatedValues: { status: "pending" } })
    )
    const payload = {
      type: "message",
      info: {
        sender: MESSAGE_SENDER.USER,
      },
      metadata: {
        startNewSession,
        testBot: isTestBot,
      },
      message,
    }
    dispatch(sendMessage(payload))
  }

  const handleMessageSeen = useCallback((id, readStatus) => {
    const payload = {
      id,
      updatedValues: {
        readStatus,
      },
    }
    console.log("handleMessageSeen", payload)
  }, [])

  return (
    <Box
      id="chatbotBody"
      ref={bodyRef}
      flex={1}
      overflow="auto"
      pb={notification ? 0 : 8}
      position="relative"
    >
      {(!tenantId || tenantId === "null") && <Alert severity="error">
        <Typography>Invalid Bot URL (tenantId is missing!)</Typography>
      </Alert>}
      {loading ? (
        <Box p={1} my={1} display="flex">
          <Skeleton
            animation="wave"
            variant="circular"
            width={20}
            height={20}
            sx={{ mr: 1 }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width={100}
            height={50}
            sx={{ mr: 1 }}
          />
        </Box>
      ) : (
        messages.map((message, index) => {
          if (
            message?.sender === MESSAGE_SENDER.SYSTEM &&
            message?.payload?.text
          )
            return (
              <ErrorBoundary key={message.id}>
                <Box
                  id={message.id}
                  p={2}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography
                    variant="caption"
                    component="p"
                    align="center"
                    color="text.secondary"
                    sx={{ bgcolor: "action.hover", px: 1.5, borderRadius: 1 }}
                  >
                    {message.payload.text}
                    {message.displayFailureDetails && message?.failureDetails ? <Alert variant="outlined" severity="warning" sx={{py:0, my:1}} action={
                      <Button color="inherit" size="small" onClick={()=>copyToClipboard(`(${message?.failureDetails?.code}) ${message?.failureDetails?.internalMessage}`)}>
                        COPY
                      </Button>
                    }><Typography
                      variant="caption">({message?.failureDetails?.code}) {message?.failureDetails?.internalMessage}</Typography></Alert>: null}
                  </Typography>
                </Box>
              </ErrorBoundary>
            )

          const isLast = messages[index + 1]?.sender !== message.sender
          const isNewUserMsg =
            message.sender === MESSAGE_SENDER.USER &&
            index === messages.length - 1

          return (
            <ErrorBoundary key={message.id}>
              {!notification && index === 0 && message.timestamp && (
                <Box display="flex" justifyContent="center" mt={1}>
                  <Chip
                    label={dayjs(message.timestamp).format("DD/MM/YYYY")}
                    size="small"
                  />
                </Box>
              )}
              <MessageWrapper
                id={message.id}
                trainingDisabled={!message.chatLogId}
                chatLogId={message.chatLogId}
                isLast={isLast}
                isNewUserMsg={isNewUserMsg}
                sender={message.sender}
                senderInfo={message.senderInfo}
                timestamp={message.timestamp}
                status={message.status}
                readStatus={message.readStatus}
                flag={message.flag}
                markingFlag={message.markingFlag}
                onClickFlag={() => handleFlagClick(message)}
                onRetry={() => handleRetrySend(message)}
                onMessageSeen={handleMessageSeen}
                notification={notification}
                customerVote={message.customerVote}
                failureDetails={message.displayFailureDetails ? message.failureDetails : null}
                showEditAndTrain={isTraining && message.sender === MESSAGE_SENDER.CHATBOT && message.type === MESSAGE_TYPES.TEXT}
                editAndTrainPayload={{
                  query : messages?.[index - 1]?.payload?.text || "",
                  response : message?.payload?.text,
                  chatLogId: message.chatLogId,
                }}
              >
                {_.get(message, 'payload.relayData.data[0].image', null) && (
                  <Stack direction={'row'} alignItems={'center'} justifyContent={message.sender === MESSAGE_SENDER.USER ? 'end' : 'start'} sx={{
                    bgcolor: alpha('#000', 0.2),
                    mb: 1,
                    p:1,
                    borderRadius: 2,
                  }}>
                    <Box
                      component={'img'}
                      display={'block'}
                      borderRadius={2}
                      width={80}
                      height={80}
                      id={_.get(message, 'payload.relayData.data[0].image', '')}
                      src={_.get(message, 'payload.relayData.data[0].image', '')}
                      sx={{
                        objectFit: 'cover',
                      }} />
                  </Stack>
                )}
                {message.type === MESSAGE_TYPES.UPLOADED_DOCUMENT ? (
                  <UploadedDocumentMessage payload={message.payload} />
                ) : message.sender === MESSAGE_SENDER.USER ? (
                  <Typography variant="p" sx={{ wordBreak: "break-word", display: 'block' }} textAlign={'end'} width={'100%'}>
                    {message?.payload?.text}
                  </Typography>
                ) : _.get(message, "payload.text[0]", "") === '<' ? (
                  <Box
                    sx={{ wordBreak: "break-word" }}
                    dangerouslySetInnerHTML={{
                      __html: message?.payload?.text,
                    }}
                  />) : (
                  <Markdown components={{
                    a:(props)=>{
                      const {href, children} = props;
                      return <a target={"_blank"} href={href}>{children}</a>
                  },pre: (props) => {
                      return <pre style={{
                        backgroundColor: "rgba(0, 0, 0, 0.9)",
                        color: "white",
                        width: "100%",
                        overflow: "auto",
                        padding: "12px",
                        borderRadius: "8px",
                      }}>
                        {props.children}
                        </pre>
                    }
                  }}>
                    {message?.payload?.text}
                  </Markdown>
                )}
                {_.get(message, "payload.image", null) && (
                  <Box
                    component={'img'}
                    src={_.get(message, "payload.image", '')}
                    alt="Image"
                    display={'block'}
                    borderRadius={bubbleShape === BUBBLE_SHAPE.ROUNDED ? 4 : bubbleShape === BUBBLE_SHAPE.CORNERED ? 2 : 1}
                    minWidth={200}
                    my={1}
                    width={'100%'} />
                )}
                {message?.payload?.form?.length > 0 && (
                  <Suspense>
                    <DynamicForm
                      form={message.payload.form}
                      userConsent={message.payload?.userConsent}
                      submitBtnText={message.payload.submitBtnText}
                      btnProps={message.payload.btnProps}
                      disabled={
                        message.payload.disabled || message.status === "pending"
                      }
                      onSubmit={(form) =>
                        handleFormSubmit({
                          ...message,
                          payload: {
                            ...message.payload,
                            ...form,
                          },
                        })
                      }
                    />
                  </Suspense>
                )}
                {message.payload?.buttons?.length > 0 &&
                  message.payload.buttons.map((button, i) => {
                    return (
                      <ErrorBoundary key={`${i}-${button.id || 1}`}>
                        <Button
                          size="small"
                          variant="outlined"
                          sx={{
                            px: 0.5,
                            py: 0,
                            minWidth: "50px",
                            mr: 0.5,
                            mt: 0.5,
                          }}
                          disableElevation
                          {...button?.props}
                          onClick={() => handleButtonClick(button)}
                        >
                          {button?.text}
                        </Button>
                      </ErrorBoundary>
                    )
                  })}
              </MessageWrapper >
              {message.payload?.carousel ? <MessageCorouselData carouselData={message.payload?.carousel}/> : null}
            </ErrorBoundary>
          )
        })
      )}
      {!notification && typingInfo?.isTyping && (
        <MessageWrapper sender={MESSAGE_SENDER.CHATBOT}>
          <JumpingDots />
        </MessageWrapper>
      )}
      <GlobalSnackBar />
    </Box>
  )
}

Body.propTypes = {
  messages: PropTypes.array,
  notification: PropTypes.bool,
}

Body.defaultProps = {
  messages: [],
  notification: false,
}

export default Body
