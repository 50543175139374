import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"

import { IMG_ERROR } from "../../../data/assets"

const ShowMessage = ({
  message,
  title,
  containerProps,
  avatarSx,
  children,
  ...restProps
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      {...containerProps}
    >
      <Avatar
        variant="square"
        src={IMG_ERROR}
        alt="Info"
        sx={(theme) => ({
          height: 120,
          width: 120,
          my: 2,
          opacity: theme.palette.action.disabledOpacity,
          ...avatarSx,
        })}
        {...restProps}
      />

      {title && (
        <Typography variant="h6" align="center" color="text.secondary">
          {title}
        </Typography>
      )}
      {message && (
        <Typography align="center" color="text.disabled">
          {message}
        </Typography>
      )}
      {children}
    </Box>
  )
}

ShowMessage.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  avtarSx: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  containerProps: PropTypes.object,
}

ShowMessage.defaultProps = {
  title: "Error!!",
  message: "Something went wrong",
  avatarSx: {},
  containerProps: {},
  children: null,
}

export default ShowMessage
