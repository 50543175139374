import PropTypes from "prop-types"
import { lazy, useEffect, Suspense } from "react"
import { useSelector, useDispatch } from "react-redux"
import Paper from "@mui/material/Paper"

import {DEFAULT_THEME_VARIABLES, IFRAME_EVENT_TYPE} from "../../../data/configs/constants"
import { makeSocketConnection } from "../../../data/store/botSlice"

import Header from "../../components/Header"
import Footer from "../../components/Footer"
import ChatBody from "./ChatBody.js"
import SpinnerLoader from "../../components/SpinnerLoader"
import ShowRelayData from "../../components/ShowRelayData"

const KnowledgeSource = lazy(() => import("../../components/KnowledgeSource"))

const Chatbot = ({ className }) => {
  const dispatch = useDispatch()
  const mobileView = useSelector((state) => state.themeDetails.mobileView)
  const fullScreenBot = useSelector((state) => state.themeDetails.fullScreenBot)
  const botPosition = useSelector((state) => state.themeDetails.botPosition)
  const isSocketConnected = useSelector(
    (state) => state.botDetails.isSocketConnected
  )
  const showKnowledgeSource = useSelector(
    (state) => state.botDetails.showKnowledgeSource
  )

  useEffect(() => {
    if (!isSocketConnected) dispatch(makeSocketConnection())
  }, [dispatch, isSocketConnected])

  return (
    <Paper
      className={className}
      elevation={mobileView || fullScreenBot ? 0 : 3}
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        transformOrigin: fullScreenBot ? "" : `${botPosition} bottom`,
        position: "fixed",
        left: mobileView || fullScreenBot ? 0 : "8px",
        right: mobileView || fullScreenBot ? 0 : "8px",
        top: mobileView || fullScreenBot ? 0 : "8px",
        bottom:
          mobileView || fullScreenBot
            ? 0
            : `calc(${DEFAULT_THEME_VARIABLES.TRIGGER_SIZE} + ${DEFAULT_THEME_VARIABLES.TRIGGER_BOT_OFFSET})`,

        borderRadius: `${mobileView || fullScreenBot ? 0 : "16px"}`,
      }}
    >
      {showKnowledgeSource ? (
        <Suspense fallback={<SpinnerLoader />}>
          <KnowledgeSource />
        </Suspense>
      ) : (
        <>
          <Header />
          <ChatBody />
          <Footer />
        </>
      )}
    </Paper>
  )
}

Chatbot.propTypes = {
  className: PropTypes.string,
}

Chatbot.defaultProps = {
  className: "",
}

export default Chatbot
