import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card, CardMedia, Divider, IconButton, Stack, Typography, styled } from "@mui/material";
import Button from "@mui/material/Button";
import { uniqueId } from "../../../data/configs/utils";
import { MESSAGE_SENDER, MESSAGE_TYPES } from "../../../data/configs/constants";
import { addMessages, addNewRelayData, sendMessage } from "../../../data/store/botSlice";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { getClickableLink } from '../../../data/configs/utils';
import { useEffect, useRef } from 'react';
import { grey } from '@mui/material/colors';

const CAROUSEL_WIDTH = 200;


const MessageCorouselData = function ({ carouselData = [] }) {
  const carouselTrackRef = useRef(null)
  const leftArrowRef = useRef(null)
  const rightArrowRef = useRef(null)

  const handleTrackScroll = (e) => {
    const leftScroll = carouselTrackRef.current.scrollLeft
    const rightScroll = carouselTrackRef.current.scrollWidth - carouselTrackRef.current.clientWidth - leftScroll
    leftArrowRef.current.style.visibility = leftScroll > 0 ? 'visible' : 'hidden'
    rightArrowRef.current.style.visibility = rightScroll > 0 ? 'visible' : 'hidden'
  }

  useEffect(() => {
    handleTrackScroll()
    if (carouselTrackRef.current) {
      carouselTrackRef.current.addEventListener('scroll', handleTrackScroll)
    }
    return () => {
      if (carouselTrackRef.current) {
        carouselTrackRef.current.removeEventListener('scroll', handleTrackScroll)
      }
    }
  }, [])

  const handleCarouselSlide = (direction) => {
    if (direction === 'left') {
      carouselTrackRef.current.scrollBy({
        left: -CAROUSEL_WIDTH,
        behavior: 'smooth'
      })
    } else {
      carouselTrackRef.current.scrollBy({
        left: CAROUSEL_WIDTH,
        behavior: 'smooth'
      })
    }
  }

  return <Box className="scroll-visibility" width={'100%'} position={"relative"} paddingX={1}>
    <StyledIconButton className='Animated FadeIn' ref={leftArrowRef} onClick={handleCarouselSlide.bind(this, "left")} size='small' sx={{
      position: 'absolute',
      zIndex: 10,
      top: '50%',
      left: 10,
      display: carouselData?.length > 2 ? 'auto' : 'none',
      boxShadow: 3,
      visibility: 'hidden'
    }}>
      <i class="ri-arrow-left-line"></i>
    </StyledIconButton>
    <StyledIconButton className='Animated FadeIn' ref={rightArrowRef} onClick={handleCarouselSlide.bind(this, "right")} size='small' sx={{
      position: 'absolute',
      zIndex: 10,
      top: '50%',
      right: 10,
      display: carouselData?.length > 2 ? 'auto' : 'none',
      boxShadow: 3
    }}>
      <i class="ri-arrow-right-line"></i>
    </StyledIconButton>
    <Box ref={carouselTrackRef} sx={{
      display: 'flex',
      alignItems: 'stretch',
      gap: 2,
      width: '100%',
      overflow: 'scroll'
    }}>
      {carouselData?.map((corousel, index) => <Box key={index}>
        <CorouselComponent corousel={corousel} />
      </Box>)}
    </Box>
  </Box>
}

function CorouselComponent({ corousel }) {
  const dispatch = useDispatch()
  const handleButtonClick = (button) => {
    if (button?.href) {
      const link = getClickableLink(button.href);
      window.open(link, "_blank")
      return
    }
    if (button.text) {
      const message = {
        id: uniqueId(),
        type: MESSAGE_TYPES.TEXT,
        sender: MESSAGE_SENDER.USER,
        payload: {
          text: button.text,
        },
        status: "pending",
        timestamp: new Date().getTime(),
      }
      dispatch(addNewRelayData(corousel))
      dispatch(addMessages({ messages: [message] }))
      dispatch(
        sendMessage({
          type: "message",
          info: {
            sender: MESSAGE_SENDER.USER,
          },
          metadata: {
            button,
          },
          message,
        })
      )
    }
  }

  const handleShowSimilarClicked = () => {
    handleButtonClick({
      text: 'Show Similar Products',
    })
  }
  
  const handleKnowMoreClicked = () => {
    dispatch(addNewRelayData(corousel))
  }

  return <Card className='Animated SlideLeft' variant='outlined' sx={{
    width: CAROUSEL_WIDTH,
    height: '100%',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
  }}>
    <CardMedia
      id={corousel.image}
      image={corousel.image}
      sx={{
        width: CAROUSEL_WIDTH,
        height: CAROUSEL_WIDTH,
        backgroundColor: grey[100],
        position: 'relative',
      }}>
      <Stack direction={'column'} spacing={2} sx={{
        position: 'absolute',
        bottom: 10,
        right: 10,
        display: corousel.type === "product" ? 'auto' : 'none',
      }}>
        <StyledIconButton onClick={handleKnowMoreClicked} sx={{
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.4)'
        }}>
          <i class="ri-message-3-line"></i>
        </StyledIconButton>
        <StyledIconButton onClick={handleShowSimilarClicked} sx={{
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.4)',
        }}>
          <i class="ri-function-add-line"></i>
        </StyledIconButton>
      </Stack>

    </CardMedia>
    <Divider />
    <Box sx={{ p: 1, flexGrow: 1 }}>
      <Typography fontWeight={500} lineHeight={1.2} gutterBottom>{corousel.text}</Typography>
      <Typography gutterBottom>{corousel.subtitle}</Typography>
    </Box>
    <Box sx={{ p: 1 }}>
      {corousel?.buttons?.length > 0 &&
        corousel.buttons.map((button, i) => {
          return (
            <Button
              size="small"
              variant="outlined"
              sx={{
                minWidth: "50px",
                mr: 0.5,
                mt: 0.5,
                borderRadius: 4,
                wordBreak: 'break-word'
              }}
              disableElevation
              fullWidth
              {...button?.props}
              onClick={() => handleButtonClick(button)}
            >
              {button?.text}
            </Button>
          )
        })}
    </Box>
  </Card>
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

export default MessageCorouselData
