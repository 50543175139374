import { useMemo, useEffect, useRef, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CloseIcon from "@mui/icons-material/Close"
import InsertCommentIcon from "@mui/icons-material/InsertComment"

import {
  addMessages,
  getBotCurrentState,
  toggleBotInterface,
  updateBotState,
  updateUserLoginData,
} from "../data/store/botSlice"
import { getThemeConfig, updateThemeState } from "../data/store/themeSlice"
import { IFRAME_EVENT_TYPE, LOCAL_STORAGE } from "../data/configs/constants"
import themeConfig from "../data/theme"
import useDelayUnmount from "../data/hooks/useDelayUnmount"
import {
  getUrlParams,
  isAppleDevice,
  throttled,
  getBrowserDetails, sendUpdateToParent, isEmptyObject, setDataInLocalStorage, getDataFromLocalStorage,
} from "../data/configs/utils"

import ErrorBoundary from "./components/ErrorBoundary"
import ShowMessage from "./components/ShowMessage"
import Chatbot from "./core/Chatbot"
import Notifications from "./core/Notifications"
import 'remixicon/fonts/remixicon.css'
import * as _ from 'lodash'

const PARAMS = getUrlParams()

const App = () => {
  const dispatch = useDispatch()
  const scrollTimerRef = useRef(null)
  const [parentPathName, setParentPathName] = useState(
    PARAMS.initialParentPathName || ""
  )

  const themeMode = useSelector((state) => state.themeDetails.themeMode)
  const primaryColor = useSelector((state) => state.themeDetails.primaryColor)
  const botPosition = useSelector((state) => state.themeDetails.botPosition)
  const secondaryColor = useSelector(
    (state) => state.themeDetails.secondaryColor
  )
  const fontSize = useSelector((state) => state.themeDetails.fontSize)
  const direction = useSelector((state) => state.themeDetails.direction)
  const triggerUrl = useSelector((state) => state.themeDetails.triggerUrl)
  const triggerShape = useSelector((state) => state.themeDetails.triggerShape)

  const loadError = useSelector((state) => state.themeDetails.loadError)
  const loading = useSelector((state) => state.themeDetails.loading)
  const fullScreenBot = useSelector((state) => state.themeDetails.fullScreenBot)
  const triggerSize = useSelector((state) => state.themeDetails.triggerSize)
  const isBotOpen = useSelector((state) => state.botDetails.isBotOpen)
  const tenantId = useSelector((state) => state.botDetails.tenantId)
  const psid = useSelector((state) => state.botDetails.psid)
  const isTestBot = useSelector((state) => state.botDetails.isTestBot)
  const loginAttributes = useSelector((state) => state.themeDetails.userLoginAttributes);

  const delayUnmountBot = useDelayUnmount(isBotOpen, 500)

  let theme = useMemo(
    () =>
      createTheme(
        themeConfig[themeMode]({
          primaryColor,
          secondaryColor,
          fontSize,
          direction,
        })
      ),
    [themeMode, primaryColor, secondaryColor, fontSize, direction]
  )

  theme = responsiveFontSizes(theme)

  const init = useCallback(() => {
    if(!tenantId || tenantId === "null") {
      return;
    }
    const payload = { tenantId, data: { isTestBot } }
    dispatch(getThemeConfig(payload))
      .unwrap()
      .then((res) => {
        if (res.data?.quickReplies?.length > 0){
          dispatch(updateBotState({ quickReplies: res.data.quickReplies }));
        }
        sendUpdateToParent(IFRAME_EVENT_TYPE.MOVE_TO_LEFT, {botPosition : res.data.botPosition})
        if (res.data.routeBasedNotifications)
          dispatch(
            updateBotState({
              routeBasedNotifications: res.data.routeBasedNotifications,
            })
          )
        if (res.data.welcomeMessage?.length > 0) {
          let _welcomeMessageQuickReplies = [];
          _.get(res, 'data.welcomeMessage', []).forEach((item, index) => {
            if (_.get(item, 'quickReplies.length', 0) > 0) {
              _welcomeMessageQuickReplies = [..._welcomeMessageQuickReplies, ...item.quickReplies]
            }
          })
          dispatch(
            addMessages({
              messages: res.data.welcomeMessage,
              skipUnread: true,
            })
          )
          dispatch(
            updateBotState({
              welcomeMessages: res.data.welcomeMessage,
              quickReplies: [..._welcomeMessageQuickReplies],
            })
          )
        }
      })
    dispatch(
      getBotCurrentState({
        psid,
        tenantId,
      })
    )
  }, [dispatch, isTestBot, psid, tenantId])

  const setUserDetails = useCallback((data) => {
    let payload = {}
    for(let key in data) {
      if(key in loginAttributes) {
        if(typeof(data[key]) !== 'string') {
          sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_ERROR, {error: 'Please Provide all the details in string format'});
          return;
        }
        if(loginAttributes[key].label === 'Email' &&  data[key].length > 50) {
          sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_ERROR, {error: 'Email should be less than equal to 50 characters'});
          return;
        }
        if(loginAttributes[key].label === 'Name' && data[key].length > 50) {
          sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_ERROR, {error: 'Name should be less than equal to 50 characters'});
          return;
        }
        if(loginAttributes[key].label === 'Phone No.') {
          if(data[key].length > 24) {
            sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_ERROR, {error: 'Phone Number should be less than equal to 24 digits'});
            return;
          }
        }
        if(loginAttributes[key].label === 'User ID') {
          if(data[key].length > 50) {
            sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_ERROR, {error: 'User Id should be less than equal to 24 digits'});
            return;
          }
        }
        payload = {
          ...payload,
          [key] : data[key]
        }
      }
    }
    dispatch(updateUserLoginData(payload));
    sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_SUCCESS, {success: 'User Details Updated Successfully'});
  },[dispatch,loginAttributes])

  useEffect(() => {
    const localData = getDataFromLocalStorage(LOCAL_STORAGE.LOGIN_ATTRIBUTES);
    if (localData && !isEmptyObject(localData)) {
      setUserDetails(localData)
    }
  }, [setUserDetails])

  useEffect(() => {
    dispatch(
      updateThemeState({
        deviceData: {
          os: navigator.platform,
          userAgent: navigator.userAgent,
          browser: getBrowserDetails(),
        },
      })
    )
  }, [dispatch])

  useEffect(() => {
    init()
    if (PARAMS.defaultOpen === "true" || fullScreenBot) {
      if (fullScreenBot)
        dispatch(toggleBotInterface({ reason: "fullScreenBot" }))
      else dispatch(toggleBotInterface())
    }
  }, [dispatch, fullScreenBot, init])

  useEffect(() => {
    const handleUpdateFromParent = (e) => {
      if (e.origin !== PARAMS.parentOrigin) return
      const { type, payload } = e.data
      if (type === IFRAME_EVENT_TYPE.ROUTE_PATH_CHANGED && payload.pathname) {
        setParentPathName(payload.pathname)
      }
      if(type === IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES) {
        // storing user details in local storage to handle refresh
        setDataInLocalStorage(LOCAL_STORAGE.LOGIN_ATTRIBUTES, payload);
        setUserDetails(payload)
      }
      if(type === IFRAME_EVENT_TYPE.MAXIMIZE_CHATBOT) {
        if(!isBotOpen) handleTriggerClick()
      }
      if(type === IFRAME_EVENT_TYPE.MINIMIZE_CHATBOT) {
        if(isBotOpen) handleTriggerClick()
      }
    }

    window.addEventListener("message", handleUpdateFromParent)
    return () => {
      window.removeEventListener("message", handleUpdateFromParent)
    }
  }, [setUserDetails, isBotOpen])

  useEffect(() => {
    const isApple = isAppleDevice()
    const handleScrollbarView = throttled((event) => {
      const botContainer = document.getElementById("chatbot")
      if (botContainer && botContainer.contains(event.target)) {
        event.target.style.setProperty("--scroll-display", "visible")
        event.target.classList.add("prevent-inheritance")
        if (scrollTimerRef.current) {
          clearTimeout(scrollTimerRef.current)
        }
        scrollTimerRef.current = setTimeout(() => {
          event.target.style.setProperty("--scroll-display", "none")
        }, 800)
      }
    }, 800)
    if (!isApple) {
      const element = document.getElementById("chatbot")
      if (element) {
        element.classList.add("scroll-visibility")
        window.addEventListener("scroll", handleScrollbarView, true)
      }
    }
    return () => {
      if (!isApple) {
        window.removeEventListener("scroll", handleScrollbarView)
        if (scrollTimerRef.current) clearTimeout(scrollTimerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (direction === "rtl") document.dir = "rtl"
  }, [direction])


  const handleTriggerClick = () => {
    dispatch(toggleBotInterface())
  }

  if (!fullScreenBot && (loading || loadError)) return null
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!fullScreenBot && (
        <Box
          position="fixed"
          bottom="10px"
          p={1.5}
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow={2}
          overflow="hidden"
          backgroundColor="primary.main"
          color="primary.contrastText"
          height={triggerSize}
          width={triggerSize}
          sx={{
            [botPosition]: "10px",
            cursor: "pointer",
            display: PARAMS.customAvatar === "true" && !isBotOpen ? "none" : "flex",
            "&:hover": {
              transform: "scale(1.1)",
              transitionDuration: "500ms",
            },
            ...(triggerShape === "cornered"
              ? {
                  borderTopLeftRadius: theme.spacing(5),
                  borderTopRightRadius: theme.spacing(5),
                  borderBottomLeftRadius: theme.spacing(5),
                  borderBottomRightRadius: theme.spacing(0.5),
                }
              : triggerShape === "rounded"
              ? { borderRadius: 2 }
              : { borderRadius: "50%" }),
          }}
          onClick={handleTriggerClick}
        >
          {isBotOpen ? (
            <CloseIcon
              className="Animated ZoomIn"
              fontSize="large"
            />
          ) : triggerUrl ? (
            <Box
              className="Animated ZoomIn"
              component="img"
              src={triggerUrl}
              alt="trigger"
              height="100%"
            />
          ) : (
            <InsertCommentIcon
              className="Animated ZoomIn"
              fontSize="medium"
            />
          )}
        </Box>
      )}
      {fullScreenBot && !loading && loadError ? (
        <ShowMessage
          title="Something went wrong"
          message="We are unable to load the chatbot configuration. You can click on the retry button to load it again"
          containerProps={{
            height: "100vh",
          }}
        >
          <Button
            variant="contained"
            sx={{ my: 2 }}
            onClick={init}
            disableElevation
          >
            Retry
          </Button>
        </ShowMessage>
      ) : (
        <>
          {delayUnmountBot && (
            <Chatbot
              className={
                isBotOpen
                  ? `Animated ${
                      fullScreenBot ? "FadeIn" : "ZoomIn"
                    }`
                  : "Animated ZoomOut"
              }
            />
          )}
          <ErrorBoundary>
            {!isBotOpen && <Notifications parentPathName={parentPathName} />}
          </ErrorBoundary>
        </>
      )}
    </ThemeProvider>
  )
}

export default App
